exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cennik-js": () => import("./../../../src/pages/cennik.js" /* webpackChunkName: "component---src-pages-cennik-js" */),
  "component---src-pages-galeria-js": () => import("./../../../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-od-nas-js": () => import("./../../../src/pages/od-nas.js" /* webpackChunkName: "component---src-pages-od-nas-js" */),
  "component---src-pages-okolica-js": () => import("./../../../src/pages/okolica.js" /* webpackChunkName: "component---src-pages-okolica-js" */),
  "component---src-pages-pokoje-js": () => import("./../../../src/pages/pokoje.js" /* webpackChunkName: "component---src-pages-pokoje-js" */)
}

